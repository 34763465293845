.flip-card {
    background-color: transparent;
    width: 230px;
    height: 230px;
    position: "relative";
    padding: "15px";
    /* perspective: 1000px; */
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    border-radius: 5%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    /* transform: rotateY(-180deg); */ 
    border-radius: 5% !important;
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
  
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #ffffff;
    color: black;
    border-radius: 5%;
  }
  
  .flip-card-back {
    background-color: #ef5835;
    color: white;
    transform: rotateY(180deg);
    border-radius: 5%;
  }
  