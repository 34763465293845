/* General Reset */
/* *,
*::before,
*::after {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  list-style-type: none !important;
} */

/* :root {
  --primary: #6a59ff !important;
  --white: #ffffff !important;
  --bg: #f5f5f5 !important;
} */

/* html {
  font-size: 62.5% !important;
  scroll-behavior: smooth !important;
} */

::-webkit-scrollbar {
  width: 0.5rem !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem !important;
  background: #797979 !important;
  transition: all 0.5s ease-in-out !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224 !important;
}

::-webkit-scrollbar-track {
  background: #f9f9f9 !important;
}

body {
  font-size: 1.6rem !important;
  background: var(--bg) !important;
}

.container {
  max-width: 100rem !important;
  padding: 4rem 0 !important;
  margin: 0 auto !important;
}

.heading {
  padding: 1rem 0 !important;
  font-size: 3.5rem !important;
  text-align: center !important;
}

.swiper_container {
  height: 30rem !important;
  padding: 2rem 0 !important;
  position: relative !important;
}

.swiper-slide {
  width: 28rem !important;
  height: 28rem !important;
  position: relative !important;
}

.swiper-slide img {
  width: 28rem !important;
  height: 28rem !important;
  border-radius: 2rem !important;
  object-fit: cover !important;
}

@media (max-width: 1024px) {
  .swiper-slide {
    width: 27rem !important;
    height: 27rem !important;
  }
  .swiper-slide img {
    width: 27rem !important;
    height: 27rem !important;
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 21rem !important;
    height: 21rem !important;
  }
  .swiper-slide img {
    width: 21rem !important;
    height: 21rem !important;
  }
}

@media (max-width: 500px) {
  .swiper_container {
    height: 30rem !important;
  }
  .swiper-slide {
    width: 20rem !important;
    height: 20rem !important;
  }
  .swiper-slide img {
    width: 20rem !important;
    height: 20rem !important;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none !important;
}

.slider-controler {
  position: relative !important;
  bottom: 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slider-controler .swiper-button-next,
.slider-controler .swiper-button-prev {
  left: 50% !important;
  transform: translateX(-50%) !important;
  color: "#effdff" !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next,
  .slider-controler .swiper-button-prev {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next,
  .slider-controler .swiper-button-prev {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white) !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
  border-radius: 50% !important;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)) !important;
  color: #effdff !important;
}

.slider-controler .slider-arrow ion-icon {
  font-size: 1rem !important;
  color: #222224 !important;
}

.slider-controler .slider-arrow::after {
  content: '' !important;
}

/* Pagination styles can be uncommented if needed */
/*
.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
*/
